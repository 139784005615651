import { catchErrors } from 'puffy-core/error'
import { validateEmail } from 'puffy-core/validate'
import { IonPage, useIonToast, useIonModal } from '@ionic/react'
import React, { useState } from 'react'
import Button from '@mui/material/Button'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import CssBaseline from '@mui/material/CssBaseline'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Link, useHistory } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import { LOGIN_PATH, SIGNUP_PATH, FORGOT_PWD_PATH, CONFIRM_CODE_PATH, RESET_PWD_PATH, TERMS_PDF_PATH } from '../path'
import { Auth } from '../auth'
import Toast from '../utils/Toast'
import ProgressBar from '../components/ProgressBar'
import Logo from '../components/Logo'
import { isInstallable } from '../utils/browser'
import InstallAppMessage from '../components/InstallAppMessage'
import './LoginSignup.css'

const PRIVACY_POLICY_PATH = 'https://www.goannaag.com.au/privacy-policy-mojave'
const HOME_PAGE = '/'
const SIGNUP_SUB_KEY = 'goanna-wand-signup-sub'

function Copyright(props: any) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" marginTop="60px" {...props}>
			{'Copyright © Goanna AG '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

function ErrorMessage(props: any) {
	const inputMsg = `${props.children||''}`
	const msg = inputMsg.indexOf('higher limit is required') >= 0
		? 'Sorry, we have received too many applications to sign up to WAND. We will notify you as soon this issue is resolved.'
		: inputMsg
	return (
		<Typography variant="body2" color="error.main" {...props}>
			{msg}
		</Typography>
	)
}

const theme = createTheme()

export enum Modes {
	login,
	signup,
	forgotpwd,
	resetpwd,
	codeconfirm
}

const login = (username:string, password:string, history: any, setError:Function, setProcessing: Function, setIncorrectEmail: Function, setIncorrectPwd: Function) => catchErrors((async () => {
	const email = (username||'').toLowerCase().trim()
	if (!email) {
		setIncorrectEmail(true)
		setError('Missing required email')
	} else if (!validateEmail(email)) {
		setIncorrectEmail(true)
		setError(`Invalid email format`) 
	} else if (!password) {
		setIncorrectPwd(true)
		setError('Missing required password')
	} else {
		// Doc: https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in
		const [userErrors] = await catchErrors(Auth.signIn(email, password))

		// setError('Exceeded daily email limit for the operation or the account. If a higher limit is required, please configure your user pool to use your own Amazon SES configuration for sending email.')

		if (userErrors) {
			if (userErrors.some((e:any) => e.message && e.message.indexOf('is not confirm') >= 0))
				await resendConfirmSignup(email, history, setError, setProcessing)
			else {
				let errMsg = userErrors.map((e:any) => e.message).join(' ') || ''
				setError(errMsg)
			}
		} else
			history.push(HOME_PAGE)
	}

	setProcessing(false)
})())

const signup = (username:string, password:string, confirmPassword:string, history: any, setError:Function, setProcessing: Function, setIncorrectEmail:Function, setIncorrectPwd: Function, setIncorrectPwdConfirm: Function) => catchErrors((async () => {
	const email = (username||'').toLowerCase().trim()
	if (!email) {
		setIncorrectEmail(true)
		setError('Missing required email')
	} else if (!validateEmail(email)) {
		setIncorrectEmail(true)
		setError(`Invalid email format`) 
	} else if (!password) {
		setIncorrectPwd(true)
		setError('Missing required password')
	} else if (password !== confirmPassword) {
		setIncorrectPwdConfirm(true)
		setError(`Failed to confirm password`) 
	} else {
		const email = (username||'').toLowerCase().trim()
		/**
		 * Doc: https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-up
		 * user: {
		 * 		user: {
		 * 			username: 'example@gmail.com',
		 * 			pool: {
		 * 				userPoolId: 'ap-southeast-2_abcdefg',
		 * 				clientId: '123456789'
		 * 			}
		 * 		},
		 * 		userConfirmed: false,
		 * 		userSub: '1234-5678-91011-1213',
		 * 		codeDeliveryDetails: {
		 * 			AttributeName: 'email',
		 * 			DeliveryMedium: 'EMAIL',
		 * 			Destination: 'e***@***'
		 * 		}
		 * }
		 */
		const [userErrors, user] = await catchErrors(Auth.signUp({
			username: email,
			password,
			attributes: {
				email
			},
			autoSignIn: { // optional - enables auto sign in after user is confirmed
				enabled: true
			}
		}))

		if (userErrors) {
			let errMsg = userErrors.map((e:any) => e.message).join(' ') || ''
			if (errMsg.indexOf('assword did not conform with policy') >= 0) {
				setIncorrectPwd(true)
				setIncorrectPwdConfirm(true)
			} else if (errMsg.indexOf('validation error detected') >= 0 || errMsg.indexOf('validation errors detected') >= 0) {
				errMsg = 'Invalid password'
				setIncorrectPwd(true)
				setIncorrectPwdConfirm(true)
			}
			setError(errMsg)
		}
		else if (!user || !user.userSub)
			setError('Internal error: Server did not return user ID. Please contact support.')
		else {
			localStorage.setItem(SIGNUP_SUB_KEY, user.userSub)
			history.push(CONFIRM_CODE_PATH)
		}
	}

	setProcessing(false)
})())

const confirmSignup = (code:string, history: any, setError:Function, setProcessing: Function) => catchErrors((async () => {
	if (!code)
		setError(`Code is required`) 
	else {
		const username = localStorage.getItem(SIGNUP_SUB_KEY)
		if (!username)
			setError(`Missing username. Use the original code verification page that appeared after the signup/login process.`) 
		else {
			// Doc: https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#confirm-sign-up
			const [userErrors] = await catchErrors(Auth.confirmSignUp(username, code))

			if (userErrors) {
				let errMsg = userErrors.map((e:any) => e.message).join(' ') || ''
				if (errMsg.indexOf('validation error detected') >= 0 || errMsg.indexOf('validation errors detected') >= 0)
					errMsg = 'Invalid validation code'
				setError(errMsg)
			}
			else 
				setTimeout(() => { // Have to delay the redirection otherwise the 'confirmSignUp' does not have enough time to set the local storage with the authentication detail.
					history.push(HOME_PAGE)
				}, 500)
		}
	}

	setProcessing(false)
})())

const sendResetPwdEmail = (username:string, history: any, setError:Function, setProcessing: Function, setIncorrectEmail:Function) => catchErrors((async () => {
	if (!username)
		setError(`Email is required`) 
	else if (!validateEmail(username.trim())) {
		setIncorrectEmail(true)
		setError(`Invalid email format`) 
	} else {
		const email = (username||'').toLowerCase().trim()
		// Doc: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password
		const [userErrors] = await catchErrors(Auth.forgotPassword(email))

		if (userErrors) {
			let errMsg = userErrors.map((e:any) => e.message).join(' ') || ''
			setError(errMsg)
		}
		else {
			localStorage.setItem(SIGNUP_SUB_KEY, email)
			history.push(RESET_PWD_PATH)
		}
	}

	setProcessing(false)
})())

const resetPwdEmail = (code:string, password:string, confirmPassword:string ,history: any, setError:Function, setProcessing: Function, setIncorrectPwdConfirm: Function, toast: Toast) => catchErrors((async () => {
	if (!code || !password) {
		if (!code)
			setError(`Code is required`) 
		if (!password)
			setError(`Password is required`) 
		if (!confirmPassword)
			setError(`Confirm password is required`) 
		if (password !== confirmPassword) {
			setIncorrectPwdConfirm(true)
			setError(`Failed to confirm password`) 
		}
	} else {
		const username = localStorage.getItem(SIGNUP_SUB_KEY)
		if (!username)
			setError(`Missing username. Use the original reset password page that appeared after the send reset password email process.`) 
		else {
			// Doc: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#forgot-password
			const [userErrors] = await catchErrors(Auth.forgotPasswordSubmit(username, code, password))

			if (userErrors) {
				let errMsg = userErrors.map((e:any) => e.message).join(' ') || ''
				if (errMsg.indexOf('validation error detected') >= 0 || errMsg.indexOf('validation errors detected') >= 0) {
					if (errMsg.indexOf('alue at \'password\'') >= 0)
						errMsg = 'Invalid password policy'
					else
						errMsg = 'Invalid validation code'
				}
				setError(errMsg)
			}
			else {
				toast.show('Password successfully reset')
				history.push(LOGIN_PATH)
			}
		}
	}

	setProcessing(false)
})())

const resendConfirmSignup = (username:string, history: any, setError:Function, setProcessing: Function) => catchErrors((async () => {
	if (!username)
		setError(`username is required`) 
	else {
		// Doc: https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#re-send-sign-up-confirmation-code
		const [userErrors] = await catchErrors(Auth.resendSignUp(username))

		if (userErrors) {
			let errMsg = userErrors.map((e:any) => e.message).join(' ') || ''
			setError(errMsg)
		}
		else {
			localStorage.setItem(SIGNUP_SUB_KEY, username)
			history.push(CONFIRM_CODE_PATH)
		}
	}

	setProcessing(false)
})())

export const LoginSignup = ({ ...props }) => {
	const mode = props.mode || Modes.login
	const { yes:canInstalled, alreadyInstalled } = isInstallable(props.deferredPrompt)
	const disableAddToHomescreen = !canInstalled && alreadyInstalled

	const [title, btnLabel] = mode == Modes.login 
		? ['Log in', 'Log in'] 
		: mode == Modes.signup 
			? ['Sign up', 'Create account'] : mode == Modes.resetpwd 
				? ['Reset password', 'Reset password'] : mode == Modes.forgotpwd 
					? ['Forgot password', 'Send reset password email'] : ['Email verification', 'Confirm']

	const head = <Helmet>
		<title>Wand - {title}</title>
	</Helmet>

	// Manages redirection to login page if user is not authenticated
	const [processing, setProcessing] = useState(false)
	const [remember, setRemember] = useState(false)
	const [accept, setAccept] = useState(false)
	const [error, setError] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [incorrectPwd, setIncorrectPwd] = useState(false)
	const [incorrectPwdConfirm, setIncorrectPwdConfirm] = useState(false)
	const [incorrectEmail, setIncorrectEmail] = useState(false)
	const history = useHistory()
	const toast = new Toast(...useIonToast(), { duration:5000 })

	const [showInstallAppMessage, closeInstallAppMessage] = useIonModal(InstallAppMessage, {
		onCancel: () => {
			closeInstallAppMessage()
		}
	})

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (!processing) {
			setIncorrectPwd(false)
			setIncorrectPwdConfirm(false)
			setIncorrectEmail(false)
			setProcessing(true)

			const data = new FormData(event.currentTarget)
			const username = `${data.get('email')}`
			const password = `${data.get('password')}`
			const confirmPassword = `${data.get('confirm-password')}`
			const code = `${data.get('code')}`

			let errors
			if (mode == Modes.login)
				[errors] = await login(username, password, history, setError, setProcessing, setIncorrectEmail, setIncorrectPwd)
			else if (mode == Modes.signup)
				[errors] = await signup(username, password, confirmPassword, history, setError, setProcessing, setIncorrectEmail, setIncorrectPwd, setIncorrectPwdConfirm)
			else if (mode == Modes.codeconfirm)
				[errors] = await confirmSignup(code, history, setError, setProcessing)
			else if (mode == Modes.forgotpwd)
				[errors] = await sendResetPwdEmail(username, history, setError, setProcessing, setIncorrectEmail)
			else if (mode == Modes.resetpwd)
				[errors] = await resetPwdEmail(code, password, confirmPassword, history, setError, setProcessing, setIncorrectPwdConfirm, toast)
			
			if (errors) {
				setProcessing(false)
				setError(errors.map((e:any) => e.message).join(' '))
			}
		}
	}

	const handleCheckChange = (mode:string) => () => {
		if (mode == 'remember')
			setRemember(!remember)
		else if (mode == 'accept')
			setAccept(!accept)
	}

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword)	
	}

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault()
	}

	const addToHomeScreen = async () => {
		if (canInstalled) {
			// Show the install prompt
			props.deferredPrompt.prompt()
			const { outcome } = await props.deferredPrompt.userChoice
			if (outcome != 'dismissed')
				props.deferredPrompt = null
		} else {
			showInstallAppMessage()
		}
	}

	const showEmail = mode == Modes.login || mode == Modes.signup || mode == Modes.forgotpwd
	const showPwd = mode == Modes.login || mode == Modes.signup || mode == Modes.resetpwd
	const showConfirmPwd = mode == Modes.signup || mode == Modes.resetpwd
	const showCodeConfirm = mode == Modes.codeconfirm || mode == Modes.resetpwd
	const emailSendMsg = mode == Modes.codeconfirm
		? 'A verification code has just been emailed to you. Please use that code to verify your account.'
		: 'A verification code has just been emailed to you. Please use that code to reset your password.'

	return (<IonPage>
		{head}
		{processing && <ProgressBar/>}
		<ThemeProvider theme={theme}>
			<Grid container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					sx={{
						backgroundImage: `url(/assets/image/inversion-tower.webp)`,
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) =>
							t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{ overflow:'scroll', height:'100%', position:'relative' }}>
					{/*<div className="logo-beta">Beta program</div>*/}
					{!disableAddToHomescreen && <div className="install-app" onClick={addToHomeScreen}>Add this app to your homescreen</div>}
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Logo style={{ width:'200px', margin:'-20px 0px 30px 0px' }}/>
						<Typography component="h1" variant="h5">{title}</Typography>
						{showCodeConfirm && <Typography component="p" pt="30px">
							{emailSendMsg}
						</Typography> }
						<Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
							{ showEmail && <TextField
								margin="normal"
								required
								error={incorrectEmail}
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
							/>}
							{ showCodeConfirm && <TextField
								margin="normal"
								required
								fullWidth
								name="code"
								label="Code confirmation"
								type="text"
								id="code"
							/>}
							{ showPwd && <FormControl variant="outlined" fullWidth sx={{ mt:'16px', mb:'8px' }}>
								<InputLabel htmlFor="outlined-adornment-password" error={incorrectPwd}>Password</InputLabel>
								<OutlinedInput
									required
									error={incorrectPwd}
									fullWidth
									name="password"
									label="Password"
									type={showPassword ? 'text' : 'password'}
									id="password"
									autoComplete="current-password"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
											{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>}
							{ showConfirmPwd && <FormControl variant="outlined" fullWidth sx={{ mt:'16px', mb:'8px' }}>
								<InputLabel htmlFor="outlined-adornment-password" error={incorrectPwdConfirm}>Confirm Password</InputLabel>
								<OutlinedInput
									required
									fullWidth
									error={incorrectPwdConfirm}
									name="confirm-password"
									label="Confirm password"
									type={showPassword ? 'text' : 'password'}
									id="confirm-password"
									autoComplete="current-password"
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
											{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>}
							{ mode == Modes.login && <FormControlLabel
								control={<Checkbox value="remember" color="primary" onChange={handleCheckChange('remember')} />}
								label="Remember me"
							/>}
							{ mode == Modes.signup && <FormControlLabel
								sx={{ height:'70px', display:'flex', alignItems:'flex-start' }}
								control={<Checkbox value="accept" color="primary" onChange={handleCheckChange('accept')} />}
								label={
								<Box sx={{ marginTop:'9px' }}>
									<span>I accept the </span>
									<Link target="_blank" to={{ pathname:TERMS_PDF_PATH, state:{ origin:'login' }}}>terms of use</Link>
									<span> and </span>
									<Link target="_blank" to={{ pathname:PRIVACY_POLICY_PATH, state:{ origin:'login' }}}>privacy policy</Link>
								</Box>
								}
							/>}
							<Box sx={{ position: 'relative' }}>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									disabled={processing || (mode == Modes.signup && !accept)}
									size="large"
									sx={{ mt: 3, mb: 2 }}
								>{btnLabel}</Button>
								{processing && <CircularProgress
									size={24}
									sx={{
										color: theme.palette.primary.main,
										position: 'absolute',
										top: '50%',
										left: '50%',
										marginTop: '-8px',
										marginLeft: '-8px',
									}}
								/>}
							</Box>
							{ mode == Modes.login && <Grid container>
								<Grid item xs>
									<Link to={FORGOT_PWD_PATH} >Forgot password?</Link>
								</Grid>
								<Grid item>
									<Link to={SIGNUP_PATH} >
										{"Don't have an account? Sign Up"}
									</Link>
								</Grid>
							</Grid>}
							{ (mode == Modes.signup || mode == Modes.forgotpwd) && <Grid container>
								<Grid item xs>
									<Link to="#" ></Link>
								</Grid>
								<Grid item>
									<Link to={LOGIN_PATH} >
										{"Already have an account? Log in"}
									</Link>
								</Grid>
							</Grid>}
							{error && <ErrorMessage sx={{ mt: 3 }}>{error}</ErrorMessage>}
						</Box>
						<Copyright />
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	</IonPage>)
}
