import { useLazyQuery } from '@apollo/client'
import { useState } from 'react'
import { IonContent, IonPage, useIonModal, useIonToast } from '@ionic/react'
import { Redirect, useHistory } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import BoltIcon from '@mui/icons-material/Bolt'
import styled from 'styled-components'
import Toast from '../../../utils/Toast'
import { LOGIN_PATH, TERMS_PDF_PATH, SETTINGS_UPGRADE_PLAN_PATH } from '../../../path'
import { TitleHeader } from '../../../components/Header'
import { DescriptionBox } from '../Component'
import FormFeedback from '../../../components/FormFeedback'
import { redirect_to_checkout } from '../stripe'
import { GET_PAYMENT_SESSION_GQL } from '../../../data/payment'
import { useUser, get_plan } from '../../../data/user'

const MasterDiv = styled.div`
	padding:20px 26px 0px 26px;

	& .menu {
		display:flex;

		& .menu-items {
			display:flex;
		}

		& .item {
			cursor: pointer;
			border-radius: 30px;
			background-color: #e8e8e8;
			color:#757575;
			margin-right: 12px;
			padding: 6px 10px;
			font-size: 14px;
			font-weight: 400;

			&.selected {	
				background: var(--mu-primary-dark);
				background: -webkit-linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
				background: linear-gradient(to right, var(--mu-primary-dark), var(--mu-primary-light));
				color:white;
			}
		}
	}

	& .banner {
		background-color: black;
		color:white;
		height: auto;
		border-radius: 12px;
		margin-top: 18px;
		padding:12px;
		display:grid;
		grid-template-rows: 42px auto;
		grid-template-columns: 1fr auto;
		grid-template-areas:
			'title button'
			'text  text';

		& .title {
			grid-area:title;
			color:white;
			font-size:22px;
			padding-bottom:8px;
			text-transform: capitalize;
			font-weight:700;
		}
		& .button {
			grid-area:button;
			color:white;
		}
		& .text {
			grid-area:text;
			color:white;

			& h6 {
				font-size: 14px;
				font-weight: 400;
			}

			& p {
				line-height:15px;
				font-size:12px;
				color:#d5d5d5;
				margin-top: 6px;
			}

			& span, h6 {
				color:white;
			}
		}

		& .upgrade {
			display: flex;
			justify-content: end;
			padding-top: 6px;

			& .upgrade-btn {
				cursor: default;
				height: 25px;
				width: 100px;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color:white;
				color: black;
				border-radius: 30px;
				font-weight: 500;

				& .upgrade-text {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: -5px;
				}

				& svg {
					font-size:20px;
				}
			}
		}
	}

	& .footer {
		height:40px;
	}

	& .floating-footer {
		height:110px;
		background-color:white;
		position: sticky;
		bottom: 0px;
		margin-left: -26px;
		width: calc(100% + 52px);
		display:flex;
		flex-direction: column;
		align-items: center;
		justify-content: start;

		& .buy-button {
			height: 30px;
			background-color: black;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 500;
			width: 310px;
			border-radius: 40px;
			cursor: pointer;

			&.go-to-stripe {
				background-color:#595959;
				color:#949494;
			}
		}

		& .text {
			padding: 16px 26px;
			text-align: center;

			& a {
				cursor:pointer;
				margin-left: 3px;
			}
		}
	}
`

const Menu = ({ ...props }) => {
	const { selected_plan, set_selected_plan, menu_count } = props

	return (<div className="menu">{!menu_count ? null :
		<div className="menu-items">
			<div 
				onClick={() => set_selected_plan('organization')}
				className={'item' + (selected_plan == 'organization' ? ' selected' : '')}>
				Organisation
			</div>{menu_count > 1 ?
			<div 
				onClick={() => set_selected_plan('professional')}
				className={'item' + (selected_plan == 'professional' ? ' selected' : '')}>
				Professional
			</div>:null}
		</div>}
	</div>)
}

const Plan = () => {
	const head = <Helmet>
		<title>Wand - Upgrade Plan</title>
	</Helmet>

	const [selected_plan, set_selected_plan] = useState('professional')
	const [menu_count, set_menu_count] = useState(2)
	const [go_to_stripe, set_go_to_stripe] = useState(false)
	const user_op = useUser({
		onCompleted(data:any) {
			const p = data?.plan?.name || 'free'
			if (p == 'free') {
				set_selected_plan('professional')
				set_menu_count(2)
			} else if (p == 'professional') {
				set_selected_plan('organization')
				set_menu_count(1)
			} else if (p == 'organization') {
				set_selected_plan('')
				set_menu_count(0)
			}
		}
	})

	const toast = new Toast(...useIonToast(), { duration:5000 })
	const [show_org_contact_us, dismiss_org_contact_us] = useIonModal(FormFeedback, {
		mode:'org_upgrade',
		onCancel: () => {
			dismiss_org_contact_us()
		},
		onConfirm: () => {
			toast.show('Request sent')
			dismiss_org_contact_us()
		}
	})

	const theme = useTheme()
	const css_vars:any = {
		'--mu-primary-light': theme.palette.primary.light,
		'--mu-primary-dark': theme.palette.primary.dark,
		'--mu-primary-main': theme.palette.primary.main
	}
	const history = useHistory()
	const redirect = false

	const [get_payment_session] = useLazyQuery(GET_PAYMENT_SESSION_GQL, { notifyOnNetworkStatusChange: true })

	const plan = get_plan(selected_plan)
	const plan_cap = `${selected_plan}`.replace(/^./, x => x.toUpperCase())

	const upgrade = () => {
		if (plan?.name == 'organization') {
			show_org_contact_us()
		} else {
			set_go_to_stripe(true)
			get_payment_session({
				variables:{
					price_id:process.env.REACT_APP_STRIPE_PROFESSIONAL_PLAN_ID
				},
				onCompleted(data) {
					const session_id = data?.payment_session?.id
					redirect_to_checkout(session_id)
				},
				onError(...args) {
					set_go_to_stripe(false)
					console.log('onError_111')
					console.log(args)
				}
			})
		}
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> : (
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Upgrade Plan" onBack={() => history.goBack()} />
				<IonContent fullscreen>
					<div style={css_vars}>{user_op.loading ? null :
						<MasterDiv>
							<Menu selected_plan={selected_plan} set_selected_plan={set_selected_plan} menu_count={menu_count} />
							<div className="banner">
								<Typography variant="h6" component="h2" className="title">
									{selected_plan == 'organization' ? 'organisation' : selected_plan}
								</Typography>
								<div className="upgrade button">
									<div className="upgrade-btn" onClick={() => history.push(SETTINGS_UPGRADE_PLAN_PATH)}>
										<div className="upgrade-text">
											<BoltIcon/>
											Best for you
										</div>
									</div>
								</div>
								<div className="text">
									<Typography variant="h6" color="text.secondary" >
										{plan.price ? `AU$${plan.price}/year + GST` : 'Contact us'}
									</Typography>
									<Typography variant="caption" component="p" color="text.secondary" >
										Get more towers, more forecast and more collaboration
									</Typography>
								</div>
							</div>
							<DescriptionBox
								title="Features for you"
								items={plan.benefits}
								no_icon_color={true}
							/>
							<div className="footer">
							</div>
							<div className="floating-footer">
								<div 
									className={`buy-button${go_to_stripe ? ' go-to-stripe' : ''}`} 
									onClick={upgrade}>
									{plan.price ? `Get ${plan_cap} for AU$${plan.price}/y + GST` : 'Contact us'}
								</div>{!go_to_stripe ? null :
								<CircularProgress
									size={24}
									sx={{
										color: 'white',
										position: 'absolute',
										top: '15px',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}/>}
								<Typography variant="caption" component="p" className="text" color="text.secondary" >
									This is a 12-months plan. By proceeding, you have read and agree to the
									<a target="_blank" rel="noreferrer" href={TERMS_PDF_PATH}>Terms &amp; Conditions</a>.
								</Typography>
							</div>
						</MasterDiv>}
					</div>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default Plan